import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import Layout from "../../components/Layout";

const HoursPage = () => (
  <StaticQuery
    query={graphql`
      query HoursQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={data => (
      <Layout>
        <div className="section">
          <div className="container">

            <h1 className="is-size-1">Store Hours</h1>
            <br />

            <div className="columns is-multiline">
              <div className="column is-full">
                <h2 className="subtitle is-size-4">
                  <div className="card">
                    <div className="card-content customHeaderBorder">
                      <div class="columns is-mobile">
                        <div class="column has-text-centered has-text-weight-semibold is-size-6-mobile">
                          Day
                        </div>
                        <div class="column has-text-centered has-text-weight-semibold is-size-6-mobile">
                          Open
                        </div>
                        <div class="column has-text-centered has-text-weight-semibold is-size-6-mobile">
                          Close
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Monday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          8am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                          5pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Tuesday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          8am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                        5pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Wednesday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          8am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                        5pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Thursday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          8am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                        5pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Friday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          8am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                        5pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Saturday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          9am
                        </div>
                        <div class="column has-text-centered is-size-6-mobile">
                        1pm
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-size-6-mobile">Sunday</div>
                        <div class="column has-text-centered is-size-6-mobile">
                          closed
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column is-full is-size-6">
                          *subject to change during bad weather and holidays
                        </div>
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default HoursPage;
